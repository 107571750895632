  export const paginationTopLimit = {
    limit: 99,
    // allLimit: 99,
    topLimit: 999999999,
    limitPage: 1,
  };

  export const queryParams = {
    inExchange: true,
    disabled: false,
  }