

const BannerExtServ = () => {
    return(
        <div>
            
        </div>
    )
}

export default BannerExtServ;