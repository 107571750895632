import MainNav from './main-nav';

const Header = () => {
  return (
    <header className="z-50  fixed top-0 left-0 right-0 bg-transparent">
      <MainNav />
    </header>
  );
};

export default Header;
