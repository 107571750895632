import imgVerisure from '../../assets/img/externServ/bannerVerisure.webp'
import imgFedEx from '../../assets/img/externServ/FedExBanner.webp'

import LogoAdt from '../../assets/img/externServ/adtLogo.png'
import LogoStc from '../../assets/img/externServ/stcChile.png'
import LogoSYM from '../../assets/img/externServ/SYM.png'
import LogoCerrajeria from '../../assets/img/externServ/logoCerraje.png'

export const externHero = [
    {
        id:1,
        imgHero:imgVerisure,
        headings:'Verisure',
        texts:'Contrata y confia tu seguridad en Verisure',
    },
    {
        id:2,
        imgHero:imgFedEx,
        headings:'FedEx',
        texts:'Contrata y confia en FedEx',
    },
];


export const listServExt = [
    {
        id:1,
        img:LogoAdt,
        name:'Empresa ADT',
        text:'empresa dedicada a la seguridad y alarmas',
        urlWeb:'https://www.adt.cl/' 
    },
    {
        id:2,
        img:LogoStc,
        name:'Empresa STC',
        text:'empresa dedicada a la seguridad y alarmas',
        urlWeb:''
    },
    {
        id:3,
        img:LogoSYM,
        name:'Empresa SYM',
        text:'empresa dedicada a la seguridad y alarmas',
        urlWeb:''
    },
    {
        id:4,
        img:LogoSYM,
        name:'Empresa SYM',
        text:'empresa dedicada a la seguridad y alarmas',
        urlWeb:''
    },
    {
        id:5,
        img:LogoAdt,
        name:'Empresa ADT',
        text:'empresa dedicada a la seguridad y alarmas',
        urlWeb:'https://www.adt.cl/' 
    },
    {
        id:6,
        img:LogoStc,
        name:'Empresa STC',
        text:'empresa dedicada a la seguridad y alarmas',
        urlWeb:''
    },
    {
        id:7,
        img:LogoStc,
        name:'Empresa SYM',
        text:'empresa dedicada a la seguridad y alarmas',
        urlWeb:''
    },
    {
        id:8,
        img:LogoSYM,
        name:'Empresa SYM',
        text:'empresa dedicada a la seguridad y alarmas',
        urlWeb:''
    },

];

export const bannerExtServ = [
    {
        id: 1,
        img:'',
        name:'',
        urlWeb:''
    },
    {
        id: 2,
        img:'',
        name:'',
        urlWeb:''
    },
]