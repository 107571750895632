import {
  BsFacebook,
  BsInstagram,
  BsYoutube,
  BsLinkedin,
} from '../../components/icon';

export const footerSMediaNavigation = [
  {
    id: 1,
    name: 'Facebook',
    href: 'https://www.facebook.com/',
    icon: <BsFacebook />,
    color: 'text-custom-facebook',
  },
  {
    id: 2,
    name: 'Instagram',
    href: 'https://www.instagram.com/procanje?igsh=MWN1aDl5d3g5ejQ1ZA==',
    icon: <BsInstagram />,
    color: 'text-custom-facebook',
  },
  // {
  //   id: 3,
  //   name: 'Twitter',
  //   href: 'https://twitter.com/',
  //   icon: '',
  //   iconImg: '/images/footer/logo-x.png',
  //   color: 'text-custom-facebook',
  // },
  {
    id: 4,
    name: 'Youtube',
    href: 'https://www.youtube.com/',
    icon: <BsYoutube />,
    color: 'text-custom-facebook',
  },
  {
    id: 5,
    name: 'LinkedIn',
    href: 'https://www.linkedin.com/in/',
    icon: <BsLinkedin />,
    color: 'text-custom-facebook',
  },
];

export const footerNavigation = [
  {
    id: 1,
    name: 'Inicio',
    href: '/',
  },
  {
    id: 2,
    name: 'Cómo funciona',
    href: '/como-funciona',
  },
  {
    id: 3,
    name: 'Comunidad',
    href: '/comunidad',
  },
  {
    id: 4,
    name: 'Nosotros',
    href: '/nosotros',
  },
];
